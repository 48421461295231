<template>
  <div>
    <div
        v-if="chapter != null"
        class="card is-shadowless is-bordered"
        style="border: 2px dashed rgba(0, 0, 0, 0.13); padding: 16px;"
    >
      <h2 class="subtitle has-text-weight-semibold">
          <span v-if="!isEditingName" style="margin-right: 8px">{{
              chapter.name
            }}</span>
        <input
            class="input is-small"
            type="text"
            :placeholder="$t('chapter.chapterName')"
            v-model="chapter.name"
            v-if="isEditingName"
            required
            style="width: 50%; margin-right: 8px"
        />
        <small
            class="is-size-7 has-text-info link"
            @click="isEditingName = true"
            v-if="!isEditingName && !isInRemovalMode && $api.canEdit()"
        >{{ $t('chapter.edit') }}</small
        >
        <small
            class="is-size-7 has-text-success link"
            @click="save"
            v-if="isEditingName"
        >{{ $t('chapter.save') }}</small
        >
        <small
            class="is-size-7 has-text-danger link"
            style="margin-left: 8px"
            @click="
              isInRemovalMode = true;
              isEditingName = false;
            "
            v-if="!isEditingName && !isInRemovalMode && $api.canEdit()"
        >{{ $t('chapter.destroy') }}</small
        >
        <small
            class="is-size-7 has-text-danger link"
            style="margin-left: 8px"
            @click="deleteChapter"
            v-if="!isEditingName && isInRemovalMode"
        >{{ $t('chapter.confirmDestroy') }}</small
        >
        <small
            class="is-size-7 has-text-primary link"
            style="margin-left: 8px"
            @click="isInRemovalMode = false"
            v-if="!isEditingName && isInRemovalMode"
        >{{ $t('chapter.cancel') }}</small
        >
      </h2>
      <div id="trumbowyg"></div>
      <p v-html="chapter.description" v-if="!$api.canEdit()"></p>
      <small class="is-size-7 has-text-success link" @click="save" v-if="$api.canEdit()"
      >{{ $t('chapter.save') }}</small
      >
    </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";

export default {
  name: "Chapter",
  components: {HomeWrapper},
  mounted() {
    this.load();
  },
  data() {
    return {
      course: null,
      chapter: null,
      isEditingName: false,
      isWysiwygLoaded: false,
      isInRemovalMode: false,
    };
  },
  methods: {
    async load() {
      this.course = await this.$api.getCourse(this.$route.params.course_id);
      this.$api.sidebar.course = this.course;
      this.$api.sidebar.chapters = await this.$api.getChapters(this.course.id);
      this.chapter = await this.$api.getChapter(
          this.course.id,
          this.$route.params.chapter_id
      );
      this.$api.setupTracker(this);
      const self = this;
      if (!this.isWysiwygLoaded && this.$api.canEdit())
        setTimeout(() => {
          self.isWysiwygLoaded = true;
          require("./../assets/upload.js");
          jQuery("#trumbowyg").trumbowyg({
            autogrow: true,
            removeformatPasted: true,
            btns: [
              ["viewHTML"],
              ["undo", "redo"], // Only supported in Blink browsers
              ["formatting"],
              ["strong", "em", "del"],
              ["superscript", "subscript"],
              ["link"],
              ["insertImage", "upload"],
              ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
              ["unorderedList", "orderedList"],
              ["horizontalRule"],
              ["removeformat"],
              ["fullscreen"],
            ],
            plugins: {
              upload: {
                serverPath: `${self.$api.config.endpoint}uploads`,
                fileFieldName: "asset",
                urlPropertyName: "url",
              },
            },
          });
          jQuery("#trumbowyg").trumbowyg("html", self.chapter.description);
        }, 100);
      this.$forceUpdate();
    },
    async save() {
      this.chapter.description = jQuery("#trumbowyg").trumbowyg("html");
      if (this.chapter.name.length > 0) {
        await this.$api.editChapter(this.course.id, this.chapter);
      } else {
        this.chapter = await this.$api.getChapter(
            this.course.id,
            this.$route.params.chapter_id
        );
      }
      this.isEditingName = false;
    },
    async deleteChapter() {
      await this.$api.deleteChapter(this.course.id, this.chapter);
      this.$router.push(`/courses/${this.course.id}`);
    },
  },
  watch: {
    "$route.params.chapter_id": async function () {
      this.isEditingName = false;
      this.isWysiwygLoaded = false;
      this.isInRemovalMode = false;
      await this.load();
      jQuery("#trumbowyg").trumbowyg("html", this.chapter.description ?? "");
    },
  },
};
</script>

<style scoped>
.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}
</style>
